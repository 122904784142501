<template>
    <v-sheet>
        <div class="caption error--text">　　
            QRコードは一番下に表示します
        </div>
        <div >
            <user-list 
                :usersOnly=true
                :displayContent="{userPin:true, name:true}" 
                @syncData="syncData"/>
        </div>
        <div class="my-3 d-flex justify-center">
            <v-btn @click="showQRCodes = !showQRCodes" >
                選択した項目のQRコードを{{showQRCodes?'表示':'隠す'}}
            </v-btn>
        </div>
        <div class="d-flex justify-center"  v-if="numberOfQrCode !== 0">
            <v-btn @click="downloadQR" color=success>
                QRコードをまとめてダウンロード
            </v-btn>
        </div>
        <div id="qrCodeContainers" :style="showQRCodes?'display:none':''">
        </div>
    </v-sheet>
</template>

<script>
import QRCode from 'easyqrcodejs'
import UserList from '@/components/DataTables/UserList.vue'
import jsZip from 'jszip'
export default {
    data(){
        return{
            numberOfQrCode:0,
            showQRCodes:false,
            listOfQRCodes :[]
        }
    },
    methods:{
        syncData(payload){
            this.numberOfQrCode =  payload.length
            if(payload.length >0){
                document.getElementById('qrCodeContainers').innerHTML = ''
                payload.forEach(e => {
                    this.createQRCode(e)
                })
            }else{
                document.getElementById('qrCodeContainers').innerHTML = ''
            }
        },
        createQRCode(userData){
            var options = {
                text: this.generateQRContent(userData.userPin), // <--- Global Key
                width: 256,
                height: 256,
                colorDark : "#000000",
                colorLight : "#ffffff",
                correctLevel : QRCode.CorrectLevel.H, // L, M, Q, H
                dotScale: 1, // Must be greater than 0, less than or equal to 1. default is 1
                quietZone: 50,
                quietZoneColor: '#ffffff',
                title: userData.name, // contentz
                titleHeight: 30, // height, including subTitle. default is 0
                titleTop: 25, // draws y coordinates. default is 30
            };
            // Create QRCode Object
            let newChild = document.createElement('div')
            newChild.setAttribute('id','qrcode'+userData._id+'%'+userData.name)
            newChild.setAttribute('name','GeneratedQRcode')
            document.getElementById('qrCodeContainers').appendChild(newChild)
            let qrcode = new QRCode(document.getElementById("qrcode"+userData._id+'%'+userData.name), options)
            this.listOfQRCodes.push(qrcode)
        },
        generateQRContent(uPin){
            const gKeyArray = ["fcee34e6", "6f14", "4601", "98cc", "fc9f445c2975"]
            const pinArr = uPin.split("")
            let first4PinArr = new Array(4).fill("")
            let remainingPinArr
            [
                first4PinArr[0],
                first4PinArr[1],
                first4PinArr[2],
                first4PinArr[3],
                ...remainingPinArr
            ] = pinArr
            let result = gKeyArray
            for(let i = 0 ; i<4 ; i++){
                result[i] = result[i]+first4PinArr[i]
            }
            if(remainingPinArr.length !== 0) result[4] += `$${remainingPinArr.join("")}`
            return result.join("-")
        },
        downloadQR(){
            if(this.numberOfQrCode > 0){
                let zip = new jsZip()
                let link = document.createElement('a')
                link.download = '勤怠QRコード.zip'
                this.listOfQRCodes.forEach(qrElement => {
                    let fileName = qrElement._htOption.title
                    let target = qrElement._el
                    let dataURL = target.getElementsByTagName('canvas')[0].toDataURL()
                    let result  = dataURL.substr(22)
                    result = atob(result)
                    zip.file(fileName+".png", result,{binary: true} )
                    
                })                   
                zip.generateAsync({type:"base64"}).then(function (content) {
                    link.href = "data:application/zip;base64," + content;
                    link.click();
                });
            }
        },
    },
    components:{
        UserList
    }
}
</script>

<style scoped>
#qrCodeContainers{
    margin-top: 3em;
    display:grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}
</style>